<template>
  <Select
      :label="inputLabel"
      :placeholder="inputPlaceholder"
      :selectOptions="{
        options: listDBs,
        getOptionLabel : option => option.title,
        reduce : dbConfig => dbConfig.title
      }"
      v-bind:value.sync="input" 
      :labelAsColumn="labelAsColumn"
      :required="required"
      :rowClass="rowClass"
    />
</template>

<script lang="ts">
import { Component, Prop, Watch } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericInput from '@fwk-client/components/mixins/GenericInput.vue';
import Select from '@fwk-client/components/panels/input/Select.vue';
import * as api from '@fwk-client/utils/api';

@Component({
  components : {
    Select
  }
})
export default class ListDBs extends mixins<GenericInput<string>>(GenericInput) {

  listDBs:any[] = [];

  inputLabel = "";
  inputPlaceholder = "";

  created() {
    this.inputLabel = (this.label && this.label != "") ? this.label : this.$i18n.t('profile.info.firstName') as string;
    this.inputPlaceholder = (this.placeholder && this.placeholder != "") ? this.placeholder : this.$i18n.t('profile.info.firstName_placeholder') as string;

    this.updateListDBs();
  }

  updateListDBs() {
    // We need to get the list of available companies for the current logged in user
    var options:api.ApiVueOptions =  {
      app: this
    }
    api.getAPI('/api/admin/database/configs', options).then((response:any) => {
      if(response.configs) {  
        this.listDBs = response.configs;
      }
      else {
        this.listDBs = [];
      }
    });
  }
  
}
</script>