<template>
  
  <div class="ibox">
    <div class="ibox-title">
      <h2>Target DB</h2>
    </div>
    <div class="ibox-content p-md">

      <form role="form" @submit="onFormSubmit" name="targetDBForm">
        <div class="form-group row">
          <div class="col">
            Current target DB: {{currentTargetDB}}
          </div>
        </div>
        <div class="form-group row">
          <div class="col">
            <ListDBs 
                v-bind:value.sync="form.targetDB" 
                id="targetDB" 
                label="Target DB" 
                placeholder="Select a target DB" 
                labelAsColumn 
              />
          </div>
        </div>
        <button class="btn btn-primary ladda-button" data-style="zoom-in" type="submit">Update Target DB</button>
      </form>

    </div>
  </div>
        
</template>

<style scoped>
  
</style>



<script lang="ts">
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { types as applicationTypes } from '../../../../store/application';
import ListDBs from '../input/ListDBs.vue';

export default defineComponent({
    props: {
        
    },
    components: {
        ListDBs
    },
    setup(props, context) {
        const app = getApp();
        const $router = useRouter();
        const $store = useStore();

        const form:any = reactive({
          targetDB:""
        });

        const currentTargetDB = computed(() => {
          return $store.getters['application/'+applicationTypes.getters.GET_ADMIN_TARGET_DB];
        })

        const onFormSubmit = (evt:Event) => {
          evt.preventDefault();

          // We update application store
          $store.commit('application/'+applicationTypes.mutations.SET_ADMIN_TARGET_DB, form.targetDB)
        }

        return {
          currentTargetDB,
          onFormSubmit,
          form
        }
  }
})
</script>